
























































import { Component, Vue } from "vue-property-decorator";
import { readConstants } from '@/store/main/getters';
import { dispatchGetConstants } from '@/store/main/actions';

import PlatformChart from "./202204_platform_chart.vue";
import USDChart from "./202204_USD_chart.vue";
import StrategyChart from "./202204_strategy_chart.vue";

Vue.component('PlatformChart', PlatformChart);
Vue.component('USDChart', USDChart);
Vue.component('StrategyChart', StrategyChart);

@Component
export default class Plan extends Vue {
  async mounted () {
    await dispatchGetConstants(this.$store);
  }

    // update version
  checkVersion () {
    const constants = readConstants(this.$store);

    let onlineVersion = "none";
    if (constants) {
      onlineVersion = constants.version;
    }

    let localVersion = localStorage.getItem('vers');
    let updateSignal = localStorage.getItem('upt');

    // check if we need to fresh the browser
    if (onlineVersion != "none" && localVersion != onlineVersion) { // detect newer online version
      localStorage.setItem('vers', onlineVersion);
      window.location.reload();
    } else if (updateSignal == 'refresh') { // detect refresh signal
      localStorage.setItem('upt', 'no');
      window.location.reload();
    }
  }

  // adaptive screen size
  public isMobile = false;

  beforeDestroy () {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize)
    }
  }

  created () {
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.checkVersion();
  }

  onResize () {
    this.isMobile = window.innerWidth < 960;
  }
}
